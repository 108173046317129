import './App.css';
import MuiTable from "./components/MuiTable";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Pterodactyl Theme Compare</h1>
          <h6>A None Bias Theme Comparison without the BS</h6>
        <MuiTable />


      </header>
    </div>
  );
}

export default App;
