import React from 'react';
import './TableComponent.css'; // Import your CSS styles
import tick from '../assets/tick.png'
import cross from '../assets/cross.png'

const data = [
    { feature: 'Price', theme1: '14.95', theme2: '49.95', theme3: '12.45', theme4: '18.65' },
    { feature: 'Framework', theme1: 'Standalone', theme2: 'Standalone', theme3: 'Blueprint', theme4: 'Standalone' },
    { feature: 'Alerts', theme1: <img src={cross} alt="cross" />, theme2: <img src={tick} alt="tick" />, theme3: <img src={tick} alt="tick" />, theme4: <img src={tick} alt="tick" /> },
    { feature: 'Multi-Language', theme1: <img src={cross} alt="cross" />, theme2: <img src={tick} alt="tick" />, theme3: <img src={cross} alt="cross" />, theme4: <img src={cross} alt="tick" /> },
    { feature: 'Dark / Light Theme', theme1: <img src={tick} alt="tick" />, theme2: <img src={tick} alt="tick" />, theme3: <img src={tick} alt="tick" />, theme4: <img src={tick} alt="tick" /> },
    { feature: 'Multi-Layout', theme1: <img src={cross} alt="cross" />, theme2: <img src={tick} alt="tick" />, theme3: <img src={tick} alt="tick" />, theme4: <img src={cross} alt="cross" /> },
    { feature: 'Easy to Configure', theme1: <img src={tick} alt="tick" />, theme2: <img src={tick} alt="tick" />, theme3: <img src={tick} alt="tick" />, theme4: <img src={tick} alt="tick" /> },
    { feature: 'Easy to Install', theme1: <img src={cross} alt="cross" />, theme2: <img src={tick} alt="tick" />, theme3: <img src={tick} alt="tick" />, theme4: <img src={tick} alt="tick" /> },
    { feature: 'Modification Side (Admin/Client)', theme1: 'Client', theme2: 'Client', theme3: 'Client', theme4: 'Client' },
    { feature: 'Customization Options', theme1: 'Limited', theme2: 'Moderate', theme3: 'Highly', theme4: 'Moderate' },
    { feature: 'Responsive Design', theme1: <img src={tick} alt="tick" />, theme2: <img src={tick} alt="tick" />, theme3: <img src={tick} alt="tick" />, theme4: <img src={tick} alt="tick" /> },
    { feature: 'Compatibility with Pterodactyl Versions', theme1: 'Latest', theme2: 'Latest', theme3: 'Latest', theme4: 'Latest' },
    { feature: 'Custom CSS Support', theme1: <img src={cross} alt="cross" />, theme2: <img src={cross} alt="cross" />, theme3: <img src={tick} alt="tick" />, theme4: <img src={tick} alt="tick" /> },
    { feature: 'Integrated Documentation', theme1: <img src={cross} alt="cross" />, theme2: <img src={cross} alt="tick" />, theme3: <img src={tick} alt="tick" />, theme4: <img src={tick} alt="tick" /> },
    { feature: 'Community Support', theme1: <img src={tick} alt="tick" />, theme2: <img src={tick} alt="tick" />, theme3: <img src={tick} alt="tick" />, theme4: <img src={tick} alt="tick" /> },
    { feature: 'Built-in Analytics', theme1: <img src={cross} alt="cross" />, theme2: <img src={cross} alt="cross" />, theme3: <img src={tick} alt="tick" />, theme4: <img src={cross} alt="tick" /> },
    { feature: 'Server Resource Monitoring', theme1: <img src={cross} alt="cross" />, theme2: <img src={cross} alt="cross" />, theme3: <img src={cross} alt="cross" />, theme4: <img src={cross} alt="cross" /> },
    { feature: 'Server Power Management', theme1: <img src={cross} alt="cross" />, theme2: <img src={cross} alt="cross" />, theme3: <img src={cross} alt="cross" />, theme4: <img src={cross} alt="cross" /> },
    { feature: 'Plugin Friendly', theme1: <img src={tick} alt="tick" />, theme2: <img src={tick} alt="tick" />, theme3: <img src={cross} alt="cross" />, theme4: <img src={tick} alt="tick" /> },
    { feature: 'Support', theme1: 'Discord', theme2: 'Discord', theme3: 'Discord', theme4: 'Discord' },
    { feature: 'Client Modifiable', theme1: <img src={cross} alt="cross" />, theme2: <img src={cross} alt="cross" />, theme3: <img src={tick} alt="tick" />, theme4: <img src={cross} alt="cross" /> },
    { feature: 'Sidebar', theme1: <img src={tick} alt="tick" />, theme2: <img src={tick} alt="tick" />, theme3: <img src={tick} alt="tick" />, theme4: <img src={tick} alt="tick" /> },
    { feature: 'External Links', theme1: <img src={tick} alt="tick" />, theme2: <img src={tick} alt="tick" />, theme3: <img src={tick} alt="tick" />, theme4: <img src={cross} alt="cross" /> },
    { feature: 'Email CSS Alterations', theme1: <img src={tick} alt="tick" />, theme2: <img src={tick} alt="tick" />, theme3: <img src={cross} alt="cross" />, theme4: <img src={tick} alt="tick" /> },

    { feature: 'License Terms', theme1: 'Strict', theme2: 'Strict', theme3: 'Flexible', theme4: 'Strict' },
    { feature: 'Custom Routes support', theme1: <img src={cross} alt="cross" />, theme2: <img src={cross} alt="tick" />, theme3: <img src={tick} alt="tick" />, theme4: <img src={cross} alt="cross" /> },
];







    const CustomizedTable = () => {
        return (
            <div className="table-responsive">
                <table className="styled-table">
                    <thead>
                    <tr>
                        <th>Feature</th>
                        <th>Stellar</th>
                        <th>Arix</th>
                        <th>Nebula</th>
                        <th>Unix</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td>{item.feature}</td>
                            <td>{item.theme1}</td>
                            <td>{item.theme2}</td>
                            <td>{item.theme3}</td>
                            <td>{item.theme4}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    };

    export default CustomizedTable;